
import { computed, defineComponent, ref } from 'vue'
import {
	Tickets,
	User,
	Star,
	Location,
	Money,
	Setting,
	Document,
	Bell,
	Van,
} from '@element-plus/icons'
import { deleteShop } from '@/api/shop.api'
import { useRouter } from 'vue-router'
import { ElMessageBox, ElNotification } from 'element-plus'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useUserStore } from '@/pinia/user/user.pinia'
import { useWindowSize } from '@/use/useWindowSize'
import { useMiddleAccount } from '@/use/middleAccount'
import { useReactNativeEvent, REACT_NATIVE_EVENT } from '@/use/useReactNativeEvent'

export default defineComponent({
	name: 'LayoutShopDetail',
	components: {},
	setup() {
		const currentTab = ref('editShop')
		const shopStore = useShopStore()
		const userStore = useUserStore()
		const router = useRouter()
		const { isChannelType, isDealerType } = useMiddleAccount()
		const { emitEvent } = useReactNativeEvent()
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)

		const userPermissionShop = computed(
			() => userStore.getSettingShopPermission
		)
		const { mobileMode: isMobile } = useWindowSize()
		const menuList = [
			{
				path: '/shop/detail/overview',
				icon: Tickets,
				title: 'ข้อมูลร้าน',
				isShow: true,
			},
			{
				path: '/shop/detail/courier',
				icon: Van,
				title: 'เชื่อมต่อขนส่ง',
				isShow: isChannelType.value,
			},
			{
				path: '/shop/detail/user',
				icon: User,
				title: 'ผู้ดูแล',
				isShow: userPermissionShop?.value?.delete,
			},
			{
				path: '/shop/detail/editCommission',
				icon: Star,
				title: 'ตั้งค่าคอมมิชชั่น',
				isShow: computed(() => (userStore.isOwner) || userStore.isAdmin)  && !isDealerType.value
			},
			{
				path: '/shop/detail/address',
				icon: Location,
				title: 'ข้อมูลที่อยู่',
				isShow: !isDealerType.value,
			},
			{
				path: '/shop/detail/bank',
				icon: Money,
				title: 'ช่องทางการชำระเงิน',
				isShow: userPermissionShop?.value?.delete && !isDealerType.value,
			},
			{
				path: '/shop/detail/notification',
				icon: Bell,
				title: 'การแจ้งเตือน',
				isShow: userPermissionShop?.value?.delete,
			},
			{
				path: '/shop/detail/setting',
				icon: Setting,
				title: 'ตั้งค่าระบบ',
				isShow: userPermissionShop?.value?.delete,
			},
		]
		const menuFilterIsShow = computed(() =>
			menuList.filter((menu) => {
				if (isMobile.value) {
					return (
						menu.isShow &&
						menu.path !== '/shop/detail/payment-receipt'
					)
				}
				return menu.isShow
			})
		)

		const onDeleteShop = async () => {
			//
			try {
				const isConfirm = await ElMessageBox.prompt(
					'กรุณาพิมพ์  "delete shop"',
					'ยืนยันลบร้านค้า',
					{
						confirmButtonText: 'ยืนยัน',
						cancelButtonText: 'ยกเลิก',
						type: 'error',
						showClose: !isReactNativeWebview.value,
						inputErrorMessage:
						'กรุณาระบุคำว่า delete shop เพื่อยืนยันการลบ',
						inputValidator: (value: string): boolean => {
							return value === 'delete shop'
						},
					}
				)

				if (!isConfirm) return

				const shopId = shopStore.selectedShopId as string
				await deleteShop(shopId)

				// Clear Selected Data then push to select shop page
				shopStore.updateSelectedShop(null)
				router.push('/shop')

				// emitEvent to react native
				emitEvent(REACT_NATIVE_EVENT.ON_DELETE_SHOP_COMPLETE)
				
			} catch (error: any) {
				//
				if (error?.response?.data?.resDesc === 'CAN_NOT_DELETE_SHOP_BY_ORDER') {
					ElNotification({
						title: 'ไม่สำเร็จ',
						message: 'ไม่สามารถลบร้านค้าได้ เนื่องจากมีพัสดุคงค้างในระบบ',
						type: 'error',
						showClose: !isReactNativeWebview.value,
						duration: 5000
					})
				}
			}
			
		}
		return {
			currentTab,
			userPermissionShop,
			menuList,
			menuFilterIsShow,
			onDeleteShop,
		}
	},
})
